/*------- 11. About style ------ */
.phrase {
  p {
    font-family: $poppinslight;
    font-size: 25px;
    line-height: 2rem;
  }
  h1 {
    font-family: $marketfresh;
    font-size: 30px;
    color: $theme-color;
  }
}
.about-home {
  a{
    background-color: #fff;
    padding:1rem;
  }
  p {
    font-family: $poppinslight;
    font-size: 25px;
    line-height: 2rem;
  }
}

.teams-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: baseline;
  flex-direction: row;
  width: 50%;
  margin: auto;
  margin-top: 3rem;
  .team-item {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    .team-image-container {
      border-radius: 100%;
      width: 200px;
      height: 200px;
      padding: 1rem;
      border: 1px solid $secundary-color;
      .team-image {
        width: 100%;
        height: 100%;
        border-radius: 100%;
        background-color: $secundary-color;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
    .team-content {
      margin-top: 1rem;

      .team-position {
        font-family: $marketfresh;
        color: $secundary-color;
        font-size: 18px;
        margin: 0;
        line-height: 25px;
      }
      .team-name {
        font-family: $poppinslight;
        font-size: 18px;
        color: #fff;
        font-style: italic;
        margin: 0;
        line-height: 25px;
      }
      .team-description {
        font-family: $poppinslight;
        font-size: 14px;
        color: $third-color;
        margin: 0;
        line-height: 25px;
      }
    }
  }
}

.welcome-content {
  h5 {
    font-weight: 500;
    font-size: 16px;
    color: #666;
    margin: 0;
    letter-spacing: 0.3px;
  }
  h1 {
    font-size: 30px;
    font-weight: 500;
    letter-spacing: 0.3px;
    margin: 8px 0 30px;
    color: #433f3f;
    position: relative;
    display: inline-block;
    padding: 0 0 23px;
    line-height: 1;
    &:before {
      position: absolute;
      background-color: #070508;
      height: 3px;
      width: 70px;
      content: "";
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0 auto;
    }
  }
  p {
    font-size: 20px;
    color: #161615;
    span {
      font-family: $marketfresh;
    }
    #italic-med {
      font-family: $poppinslight;
      font-style: italic;
    }
    font-family: $poppinslight;
    line-height: 30px;
    @media #{$lg-layout} {
      width: 80%;
    }
    @media #{$md-layout} {
      width: 95%;
    }
    @media #{$xs-layout} {
      width: 100%;
      font-size: 15px;
    }
  }
}

/* about page */

.single-count {
  .count-icon {
    i {
      font-size: 50px;
      line-height: 1;
      display: inline-block;
      @media #{$md-layout} {
        font-size: 45px;
      }
      @media #{$xs-layout} {
        font-size: 45px;
      }
    }
  }
  h2 {
    & > span {
      color: $theme-color;
      font-size: 40px;
      font-weight: bold;
      margin: 25px 0 12px;
      display: inline-block;
      @media #{$md-layout} {
        margin: 10px 0 8px;
        font-size: 35px;
      }
      @media #{$xs-layout} {
        margin: 6px 0 8px;
        font-size: 35px;
      }
    }
  }
  span {
    color: #333;
    font-size: 20px;
    text-transform: capitalize;
    font-weight: 500;
  }
}

.team-wrapper {
  background-color: #f7f7f7;
  .team-img {
    position: relative;

    img {
      width: 100%;
    }

    .team-action {
      background: #fff none repeat scroll 0 0;
      left: 0;
      opacity: 0;
      padding: 10px;
      position: absolute;
      right: 0;
      text-align: center;
      top: 70%;
      transform: translateY(-50%);
      transition: all 0.6s ease 0s;
      width: 100%;
      z-index: 5;
      a {
        border-radius: 50px;
        color: #fff;
        display: inline-block;
        font-size: 14px;
        height: 30px;
        line-height: 28px;
        text-align: center;
        width: 30px;
        margin: 0 3px;
        &.facebook {
          border: 1px solid #3b5998;
          background-color: #3b5998;
          &:hover {
            background-color: transparent;
            color: #3b5998;
          }
        }
        &.twitter {
          border: 1px solid #55acee;
          background-color: #55acee;
          &:hover {
            background-color: transparent;
            color: #55acee;
          }
        }
        &.instagram {
          border: 1px solid #c32aa3;
          background-color: #c32aa3;
          &:hover {
            background-color: transparent;
            color: #c32aa3;
          }
        }
      }
    }
  }
  .team-content {
    padding: 20px 10px 23px;
    h4 {
      font-size: 18px;
      font-weight: 500;
      margin: 0 0 3px;
      text-transform: capitalize;
    }
    span {
      font-size: 15px;
      font-style: italic;
    }
  }
  &:hover .team-action {
    top: 50%;
    opacity: 1;
  }
}

.single-mission {
  h3 {
    font-size: 25px;
    font-weight: 500;
    margin: 0 0 20px;
    text-transform: capitalize;
    line-height: 22px;
    color: #333;
    @media #{$xs-layout} {
      margin: 0 0 10px;
    }
  }
  p {
    margin: 0;
  }
}

.about-brand-logo {
  @media #{$xs-layout} {
    &.pb-100 {
      padding-bottom: 60px;
    }
  }
}


.profile-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 3rem;
  #box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 2rem;
    @media #{$xs-layout} {
      width: 100%;
      
    }
  }
  
  .profile {
    .img-bg {
      display: flex;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      text-align: center;
      width: 100%;
      height: 30rem;
      background-size: cover;
      border-radius: 10px;
      position: relative;
      background-position: center center;
      .text {
        display: none;
        position: absolute;
      }
      &:hover {
        .text {
          width: 100%;
          display: block;
        }
        &::before {
          content: "";
          position: absolute;

          top: 0px;
          right: 0px;
          bottom: 0px;
          opacity: 0.6;
          left: 0px;
          background-color: $third-color;
        }
      }
    }

    .text {
      padding: 1rem;
      h3 {
        font-size: 15px;
        margin-bottom: 2rem;
        color: #fff;
      }
      p {
        font-size: 18px;
        color: #fff;
        @media #{$xs-layout} {
          font-size: 15px;
          
        }
      }
    }
    
    margin-top: 1rem;
    color: $theme-color;
    display: flex;
    flex-wrap: wrap;
    width: 35rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
    border: 2px solid $theme-color;
    border-radius: 10px;
    @media #{$xs-layout} {
      width: 100%;
      .img-bg {
        width: 100%;
        height: 50rem;
        background-size: cover;
        border-radius: 10px;
        background-position: center center;
        margin-top: 0rem;
      }
      .text {
        width: 100%;
      }
    }
  }
}

.activity-box{
  padding: 2rem;
  border-radius: 10px;
  border:1px solid $theme-color;
  @media #{$xs-layout} {
    width:100%;
    text-align: center;
  }
  &:hover {
   background-color: $secundary-color;
   color: #fff;
   transition: 1s;
   
  }
}

.sm-text-start{
  @media #{$xs-layout} {
  text-align: start!important;
  }
}